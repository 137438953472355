import { bootstrap } from 'schoollabs-client'
import messages from '@/assets/doc/messages.md'

export default async (nuxt, inject) => {
  const { store } = nuxt
  if (await bootstrap(nuxt, inject)) {
    await store.dispatch('userSettings/load')
    await store.dispatch('messages/update', { messages })
  }
}
